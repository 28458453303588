<template>
    <div :id="popupId" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2><img src="/img/Alerts/plusbig_icon.png" /> Task Data Point</h2>
                    <button ref="popupCloseButton" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <form v-on:submit.prevent="addTaskDataPoint" data-vv-scope="add-task">
                      <div class="row">
                          <div class="col-sm-12">
                            <h3>Data Point Name</h3>
                            <input type="text" placeholder="Task Data Point Name" name="name" v-model="taskDetails.data_point" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('add-task.name') }" />
                          </div>
                        </div>
                       <input type="submit" class="btn-lg-green" :value="editTaskDataPointFlag ? 'UPDATE TASK DATA POINT' : 'ADD TASK  DATA POINT'" :disabled="submittingForm" />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'TaskPopup',
  props: ['popupId', 'taskDetails', 'editTaskDataPointFlag'],
  data: function () {
    return {
      submittingForm: false,
      imageSelected: false,
      currentImage: ''
    }
  },
  methods: {
    addTaskDataPoint () {
      this.$validator.validateAll('add-task').then((result) => {
        if (result) {
          this.submittingForm = true
          let action = 'addTaskDataPoint'
          let message = 'added'
          if (this.editTaskDataPointFlag) {
            action = 'updateTaskDataPoint'
            message = 'updated'
          }
          this.$store.dispatch(action, this.taskDetails).then(() => {
            this.$refs.popupCloseButton.click()
            this.resetForm()
            this.$notify({ type: 'success', text: 'Task Data Point has been ' + message + ' successfully' })
            this.submittingForm = false
            this.$emit('updateTaskDataPointsList')
          }, (response) => {
            for (var key in response.body) {
              this.$notify({ type: 'error', text: response.body[key][0] })
              let field = this.$validator.fields.find({ name: key, scope: 'add-task' })

              if (field) {
                this.$validator.errors.add({
                  id: field.id,
                  field: key,
                  msg: response.body[key][0],
                  scope: 'add-task'
                })

                field.setFlags({
                  invalid: true,
                  valid: false,
                  validated: true
                })
              }
            }
            this.errorMessage = response.body.message
            this.submittingForm = false
          })
        } else {
          if (this.errors.has('add-task.password:min')) {
            this.$notify({ type: 'error', text: 'Password should be at least 6 letters' })
          } else if (this.errors.has('add-task.contact_mobile:regex')) {
            this.$notify({ type: 'error', text: 'Please enter a valid mobile number' })
          } else {
            this.$notify({ type: 'error', text: 'Please fill out all the required fields' })
          }
        }
      })
    },

    resetForm () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.errors.clear('add-task')
    }
  }
}
</script>

<style scoped>
.btn-negative {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

.image-upload {
  display: inline-block;
  width: auto;
  text-align: center;
}
</style>
