

























import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import DeleteTaskDataPointPopup from '@/components/SAdmin/DeleteTaskDataPointPopup.vue'
import TaskDataPointPopup from '@/components/SAdmin/TaskDataPointPopup.vue'
import TaskDataPointItem from '@/components/SAdmin/TaskDataPointItem.vue'

@Component({
  components: {
    TaskDataPointItem,
    DeleteTaskDataPointPopup,
    TaskDataPointPopup
  }
})
export default class TaskDataPoints extends Vue {
  public query = ''
  public limit = 500
  public offset = 0
  public actionCountry: any = null
  public taskDataPoints: any[] = []
  public totalCount = 0
  public busy: boolean = false
  public offerIdtoDelete: number = 0
  public offerLink: string = ''
  public offerIdToCraeteLink: number = 0
  public taskDataPointDetails: {id: any, dataPoint: string, type: string, status: string} = {
    id: '',
    dataPoint: '',
    type: 'TEXT_ANSWER',
    status: 'ACTIVE'
  }

  createOfferLink (offer:any) {
    this.offerIdToCraeteLink = offer.id
  }

  setActiveTaskDataPointDetails (taskDataPoint: any) {
    this.taskDataPointDetails = taskDataPoint
  }

  getTaskDataPoints (clear = true) {
    this.busy = true
    this.$store.dispatch('getTaskDataPoints', { query: this.query, limit: this.limit, offset: this.offset }).then((response) => {
      if (clear) this.taskDataPoints = []
      this.taskDataPoints.push(...response.response.body.taskDataPoints)
      this.totalCount = response.response.body.count
      this.busy = false
    }, (response) => {
      this.busy = false
    })
  }

  resetTaskDataPointsList () {
    this.taskDataPointDetails = {
      id: '',
      dataPoint: '',
      type: 'TEXT_ANSWER',
      status: 'ACTIVE'
    }
    this.taskDataPoints = []
    this.offset = 0
    this.getTaskDataPoints()
  }

  setOfferIdToDelete (offerId:number) {
    this.offerIdtoDelete = offerId
  }

  loadMore () {
    this.$data.offset += this.$data.limit
    this.getTaskDataPoints(false)
  }

  @Watch('query')
  onSearch () {
    Vue.common.delay(() => {
      this.offset = 0
      this.getTaskDataPoints()
    }, 500)()
  }

  created () {
    this.getTaskDataPoints()
    this.$store.dispatch('getAppSettingsKeyValuePair')
  }

  refresh () {
    this.offset = 0
    this.getTaskDataPoints()
  }

  get hasLoadMore (): boolean {
    return this.taskDataPoints.length < this.totalCount
  }
}
