var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box in-padd white-bg" },
    [
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.taskDataPoints, function(taskDataPoint, index) {
          return _c(
            "div",
            {
              key: taskDataPoint.id,
              staticClass: "col-xs-6 col-sm-4 item-list"
            },
            [
              _c("TaskDataPointItem", {
                attrs: { index: index, taskDataPoint: taskDataPoint },
                on: {
                  loadTaskDataPointDetails: _vm.setActiveTaskDataPointDetails
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "text-center" }, [
        _vm.hasLoadMore
          ? _c(
              "button",
              {
                staticClass: "btn-positive",
                attrs: { disabled: _vm.busy },
                on: { click: _vm.loadMore }
              },
              [_vm._v("Load more")]
            )
          : _vm._e()
      ]),
      !_vm.busy && (!_vm.taskDataPoints || _vm.taskDataPoints.length == 0)
        ? _c("h3", { staticClass: "text-center mt20" }, [
            _vm._v("\n        No Task Data Points Found.\n      ")
          ])
        : _vm._e(),
      _c("DeleteTaskDataPointPopup", {
        attrs: {
          popupId: "delete-task-data-point-popup",
          taskDataPointDetails: _vm.taskDataPointDetails
        },
        on: { updateTaskDataPointsList: _vm.resetTaskDataPointsList }
      }),
      _c("TaskDataPointPopup", {
        attrs: {
          editTaskDataPointFlag: false,
          taskDetails: _vm.taskDataPointDetails,
          popupId: "add-task-data-point-popup"
        },
        on: { updateTaskDataPointsList: _vm.resetTaskDataPointsList }
      }),
      _c("TaskDataPointPopup", {
        attrs: {
          editTaskDataPointFlag: true,
          taskDetails: _vm.taskDataPointDetails,
          popupId: "edit-task-data-point-popup"
        },
        on: { updateTaskDataPointsList: _vm.resetTaskDataPointsList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }