var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("button", {
              ref: "popupCloseButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                attrs: { "data-vv-scope": "add-task" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.addTaskDataPoint($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("h3", [_vm._v("Data Point Name")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskDetails.data_point,
                          expression: "taskDetails.data_point"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("add-task.name")
                      },
                      attrs: {
                        type: "text",
                        placeholder: "Task Data Point Name",
                        name: "name"
                      },
                      domProps: { value: _vm.taskDetails.data_point },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.taskDetails,
                            "data_point",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]),
                _c("input", {
                  staticClass: "btn-lg-green",
                  attrs: { type: "submit", disabled: _vm.submittingForm },
                  domProps: {
                    value: _vm.editTaskDataPointFlag
                      ? "UPDATE TASK DATA POINT"
                      : "ADD TASK  DATA POINT"
                  }
                })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("img", { attrs: { src: "/img/Alerts/plusbig_icon.png" } }),
      _vm._v(" Task Data Point")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }