















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class TaskDataPointItem extends Vue {
    @Prop() taskDataPoint!: any
}
