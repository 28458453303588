









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeleteTaskDataPointPopup extends Vue {
  @Prop() popupId!: string
  @Prop() taskDataPointDetails!: any

  deleteTaskDataPoint () {
    this.$http
      .delete(process.env.VUE_APP_API_BASE_URL + '/task_data_points/' + this.taskDataPointDetails.id, {
        headers: {
          'user-type': 'sadmin'
        }
      })
      .then((response) => {
        this.$emit('updateTaskDataPointsList')
        let DeleteLocationCloseButtonPopup: HTMLElement = this.$refs.detelTaskDataPointPopupCloseButton as HTMLElement
        DeleteLocationCloseButtonPopup.click()
      })
  }
}
